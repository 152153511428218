import { useEffect, ReactChild } from 'react';
import Head from 'next/head';
import { useAppMode } from 'hooks/useAppMode';
import { pageTitleVar } from 'client-state';

type Props = {
  title: string;
  h1Contents?: ReactChild;
};

export const HeadPageTitle = ({
  title,
  h1Contents,
}: Props): JSX.Element | null => {
  const appMode = useAppMode();

  useEffect(() => {
    pageTitleVar(h1Contents || title);
  }, [title, h1Contents]);

  const titleParts = [];
  if (title) titleParts.push(title);
  if (appMode === 'crassone') titleParts.push('クラッソーネ管理画面');
  if (appMode === 'insurance-agency') titleParts.push('保険代理店管理画面');
  titleParts.push('クラッソーネビズ');

  return (
    <Head>
      <title key="pageTitle">{titleParts.join(' | ')}</title>
    </Head>
  );
};

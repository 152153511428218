import { ReactChild } from 'react';
import { makeVar } from '@apollo/client';
import {
  ProjectSortItem,
  SortOrder,
  ProjectSearchInput,
  ProjectSortInput,
  CustomerSearchInput,
} from 'generated/graphql';

// client-only app state

export const pageTitleVar = makeVar<string | ReactChild>('');

export const projectSearchConditionsVar = makeVar<ProjectSearchInput>({
  titleKeyword: '',
  contractDateFrom: null,
  contractDateTo: null,
  contractPriceFrom: null,
  contractPriceTo: null,
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  invoiceClaimStatus: undefined,
  tagIds: [],
});

export const projectSortConditionVar = makeVar<ProjectSortInput>({
  itemName: ProjectSortItem.CreatedAt,
  sortOrder: SortOrder.Desc,
});

export const projectListPageVar = makeVar<number>(1);

export const customerSearchConditionVar = makeVar<CustomerSearchInput>({
  keyword: '',
  customerType: null,
  isMainCustomer: false,
});

export const projectListSearchDetailOpenVar = makeVar<boolean>(false);
